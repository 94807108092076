<button
        [matMenuTriggerFor]="userActions"
        mat-icon-button>
    <span class="relative">
        <img [src]="profilePic || userPlaceholder" class="profile-image">
        <span [ngClass]="{'mr-px mb-px': !showAvatar || !user.profilePic}"
              class="absolute right-0 bottom-0 w-2 h-2 rounded-full"
        ></span>
    </span>
</button>

<mat-menu
    #userActions="matMenu"
    [xPosition]="'before'">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>{{'other.signedInAs' | transloco}}</span>
            <span class="mt-1.5 text-md font-medium">{{user.email}}</span>
        </span>
    </button>
    <ng-container *ngIf="hasPermission('PROFILE')">
        <mat-divider class="my-2"></mat-divider>
        <button [routerLink]="['/profile']" mat-menu-item>
            <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
            <span>{{'component.profile' | transloco}}</span>
        </button>
    </ng-container>
    <mat-divider class="my-2"></mat-divider>
    <button
            (click)="signOut()"
            mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
        <span>{{'other.signOut' | transloco}}</span>
    </button>
</mat-menu>
