import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BreadcrumbItem} from "@moodeon-commons/model/breadcrumb-item";
import {BreadcrumbComponent} from "@moodeon-commons/component/breadcrumb/breadcrumb.component";
import {BasePageHeaderComponent} from "@moodeon-commons/component/base-page-header";
import {PluralizePipe} from "@moodeon-commons/pipe/pluralize.pipe";
import {TranslocoModule} from "@ngneat/transloco";

@Component({
    selector: 'mdo-page-header',
    standalone: true,
    imports: [CommonModule, BreadcrumbComponent, PluralizePipe, TranslocoModule],
    templateUrl: './page-header.component.html',
    styles: []
})
export class PageHeaderComponent extends BasePageHeaderComponent {

    @Input()
    breadcrumbItems: BreadcrumbItem[];

    @Input()
    title: string;

    @Input()
    pluralize = true;
}
