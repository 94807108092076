import {HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest} from '@angular/common/http';
import {inject} from '@angular/core';
import {AuthService} from 'app/core/auth/auth.service';
import {AuthUtils} from 'app/core/auth/auth.utils';
import {catchError, Observable, throwError} from 'rxjs';
import {KeycloakService} from "keycloak-angular";
import {AlertService} from "@moodeon-commons/service/alert.service";
import {translate} from "@ngneat/transloco";
import {TranslationHandler} from "@moodeon-commons/handler/translation-handler";
import {TokenProps} from "@app/core/auth/token-props";
import {LocalStorageHandler} from "@app/local-storage/local-storage-handler";
import {CurrencyHandler} from "@moodeon-commons/handler/currency-handler";

export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const authService = inject(AuthService);
    const keycloakService = inject(KeycloakService);
    const alertService = inject(AlertService);

    // Clone the request object
    let newReq = req.clone();

    const currentLanguage = TranslationHandler.getCurrentLanguage();
    if (currentLanguage?.code) {
        newReq = newReq.clone({
            headers: newReq.headers
                .set('x-lang', currentLanguage.code)
                .set('x-default-lang', TranslationHandler.getDefaultLangCode())
        });
    }

    const currentBranch = LocalStorageHandler.CURRENT_BRANCH.get();
    if (currentBranch?.id > 0) {
        newReq = newReq.clone({
            headers: newReq.headers.set('x-branch-id', currentBranch.id + '')
        });
    }

    const currency = CurrencyHandler.getCurrentCurrency()?.code;
    if (currency) {
        newReq = newReq.clone({
            headers: newReq.headers.set('x-currency', currency)
        });
    }

    const token = keycloakService.getKeycloakInstance()?.token;
    if (token && !AuthUtils.isTokenExpired(token)) {
        newReq = newReq.clone({
            headers: newReq.headers.set('Authorization', 'Bearer ' + token)
        });

        if (TokenProps.tenant) {
            newReq = newReq.clone({
                headers: newReq.headers.set('x-tenant', TokenProps.tenant)
            });
        }
    }

    return next(newReq).pipe(
        catchError((error) => {
            // Catch "401 Unauthorized" responses
            if (error instanceof HttpErrorResponse && error.status === 401) {
                // Sign out
                alertService.showErrorAlert(translate('message.auth.pleaseLogIn'));
                authService.signOut();
                authService.signIn(window.location.href);
            }

            return throwError(error);
        }),
    );
};
