import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ApplicationService {

    constructor(private _httpClient: HttpClient) {
    }

    getApplicationInfo() {
        return this._httpClient.get<any>(environment.apiBaseUrl + '/actuator/info');
    }
}
