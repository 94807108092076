import {Component, Inject, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from "@angular/material/snack-bar";
import {AlertData} from "@moodeon-commons/model/alert-data";
import {FuseAlertComponent} from "../../../@fuse/components/alert";
import {IconComponent} from "@moodeon-commons/component/icon/icon.component";

@Component({
    selector: 'mdo-alert',
    standalone: true,
    imports: [CommonModule, FuseAlertComponent, IconComponent],
    templateUrl: './alert.component.html',
    styles: []
})
export class AlertComponent {

    @ViewChild('errorAlert')
    fuseAlertComponent: FuseAlertComponent;

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: AlertData,
                public snackBarRef: MatSnackBarRef<any>) {
    }

    onDismiss(dismissed: boolean): void {
        if (dismissed) {
            this.snackBarRef.dismiss();
        }
    }
}
