<button (click)="buttonClick.emit($event);" *ngIf="type === 'raised'" [class]="buttonClass" [color]="color" [disabled]="disabled"
        mat-raised-button>
    <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</button>

<button (click)="buttonClick.emit($event);" *ngIf="type === 'stroked'" [class]="buttonClass" [color]="color"
        [disabled]="disabled"
        mat-stroked-button>
    <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</button>

<button (click)="buttonClick.emit($event)" *ngIf="type === 'flat'" [class]="buttonClass" [color]="color"
        [disabled]="disabled"
        mat-flat-button>
    <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</button>

<button (click)="buttonClick.emit($event)" *ngIf="type === 'icon'" [class]="buttonClass" [color]="color"
        [disabled]="disabled"
        mat-icon-button>
    <ng-container [ngTemplateOutlet]="buttonIconContent"></ng-container>
</button>

<button (click)="buttonClick.emit($event)" *ngIf="type === '' || type == null" [class]="buttonClass" [color]="color"
        [disabled]="disabled"
        mat-button>
    <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</button>

<ng-template #buttonContent class="flex items-center">
    <div class="flex items-center">
        <ng-container [ngTemplateOutlet]="buttonIconContent"></ng-container>
        <ng-content></ng-content>
    </div>
</ng-template>

<ng-template #buttonIconContent>
    <mdo-icon *ngIf="icon" [class.mr-1]="type != 'icon' && buttonContent" [class]="iconWrapperClass" [iconLib]="iconLib"
              [icon]="icon"
              [className]="iconClass" [fillColor]="iconColor" [style.fill]="iconColor"
              matPrefix></mdo-icon>
</ng-template>
