import {KeycloakService} from 'keycloak-angular';
import {environment} from "../../../environments/environment";
import {MerchantUserService} from "@app/service/merchant-user.service";
import {TokenProps} from "@app/core/auth/token-props";
import {AuthUtils} from "@app/core/auth/auth.utils";
import {LocalStorageHandler} from "@app/local-storage/local-storage-handler";

export const initKeycloak = (keycloak: KeycloakService, merchantUserService: MerchantUserService): Promise<boolean> => {
    let url = window.location.href;
    const subdomain = url.split(".")[0].replace('https://', '').replace('http://', '');
    const autoLoginUrl = environment.merchantUiBaseUrlTemplate.replace('{slug}', subdomain) + '/auto-login'
    let realm = 'merchant-' + subdomain;
    let clientId = environment.keycloak.clientId;
    const autoLogin = LocalStorageHandler.AUTO_LOGIN.get();

    if (url.startsWith(autoLoginUrl) || autoLogin) {
        LocalStorageHandler.AUTO_LOGIN.set(true);
        realm = 'moodeon-admin';
        clientId = 'admin-ui';
        TokenProps.tenant = subdomain;
    } else {
        const token = keycloak.getKeycloakInstance()?.token;
        if (token) {
            const tokenDecoded = AuthUtils._decodeToken(token);
            if (tokenDecoded['azp'] == 'admin-ui') {
                LocalStorageHandler.AUTO_LOGIN.set(true);
                realm = 'moodeon-admin';
                clientId = 'admin-ui';
                TokenProps.tenant = subdomain;
            }
        }
    }

    return keycloak.init({
        config: {
            url: environment.keycloak.authUrl,
            realm: realm,
            clientId: clientId
        },
        enableBearerInterceptor: true,
        loadUserProfileAtStartUp: true,
        initOptions: {
            onLoad: 'login-required',
            checkLoginIframe: false,
            flow: 'implicit',
            locale: LocalStorageHandler.CURRENT_LANGUAGE.get()?.code
        },
        bearerExcludedUrls: [],
        shouldAddToken(request) {
            return true;
        }
    }).then(async value => {
        await merchantUserService.getCurrentUser(true, true).toPromise();
        return Promise.resolve(value);
    });
};
