<div *transloco="let t; read:'component'"
     class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0">

    <div class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between border-b">
        <ng-content select="[pageHeader]"></ng-content>
    </div>

    <!-- Content -->
    <div class="flex flex-auto flex-col overflow-y-scroll w-full">
        <ng-content></ng-content>
    </div>
</div>
