import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {ApiResponse} from "@moodeon-commons/model/api-response";
import {Language} from "@moodeon-commons/model/language";

@Injectable({
    providedIn: 'root'
})
export class TranslationService {
    baseUrl: string = environment.apiBaseUrl;

    constructor(private _httpClient: HttpClient) {
    }

    getLanguages(): Observable<ApiResponse<Language[]>> {
        return this._httpClient.get<ApiResponse<Language[]>>(this.baseUrl + '/' + this.getContextPath());
    }

    getContextPath(): string {
        return "translations/languages";
    }
}
