import {DOCUMENT, NgIf} from '@angular/common';
import {Component, Inject, OnDestroy, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {FuseConfig, FuseConfigService} from '@fuse/services/config';
import {FuseMediaWatcherService} from '@fuse/services/media-watcher';
import {FusePlatformService} from '@fuse/services/platform';
import {FUSE_VERSION} from '@fuse/version';
import {combineLatest, filter, map, Subject, takeUntil} from 'rxjs';
import {SettingsComponent} from './common/settings/settings.component';
import {CurrenciesComponent} from "@app/layout/common/currencies/currencies.component";
import {FuseLoadingBarComponent} from "../../@fuse/components/loading-bar";
import {
    FuseNavigationService,
    FuseVerticalNavigationComponent,
    NavigationItem
} from "../../@fuse/components/navigation";
import {LanguagesComponent} from "@app/layout/common/languages/languages.component";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {UserComponent} from "@app/layout/common/user/user.component";
import {CurrentUser} from "@app/model/admin-user";
import {ImageUtils} from "@moodeon-commons/util/image-utils";
import {MerchantUserService} from "@app/service/merchant-user.service";
import {AuthService} from "@app/core/auth/auth.service";
import {CommonEvents} from "@moodeon-commons/util/common-events";
import {MatRippleModule} from "@angular/material/core";
import {TranslocoModule} from "@ngneat/transloco";
import {BranchFilterComponent} from "@app/layout/common/branch-filter/branch-filter.component";
import {LocalStorageHandler} from "@app/local-storage/local-storage-handler";
import {NotificationsComponent} from "@app/layout/common/notifications/notifications.component";
import {StoreOpenToggleComponent} from "@app/layout/common/store-open-toggle/store-open-toggle.component";

@Component({
    selector: 'layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, SettingsComponent, CurrenciesComponent, FuseLoadingBarComponent, FuseVerticalNavigationComponent, LanguagesComponent,
        MatButtonModule, MatIconModule, RouterOutlet, UserComponent, MatRippleModule, TranslocoModule, BranchFilterComponent, NotificationsComponent, StoreOpenToggleComponent],
})
export class LayoutComponent implements OnInit, OnDestroy {
    config: FuseConfig;
    layout: string;
    scheme: 'dark' | 'light';
    theme: string;
    autoLogin = false;
    userName: string;

    isScreenSmall: boolean;
    filteredNavigation: NavigationItem[] = [];
    user: CurrentUser = new CurrentUser();
    userPlaceholder = '../../../assets/images/placeholders/user_placeholder.png';
    profilePic = this.userPlaceholder;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private _activatedRoute: ActivatedRoute,
        @Inject(DOCUMENT) private _document: any,
        private _renderer2: Renderer2,
        private _router: Router,
        private merchantUserService: MerchantUserService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseConfigService: FuseConfigService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fusePlatformService: FusePlatformService,
        private authService: AuthService
    ) {
        this.autoLogin = LocalStorageHandler.AUTO_LOGIN.get() || false;
        this.userName = merchantUserService.getCurrentUserLocal()?.firstName || merchantUserService.getCurrentUserLocal()?.username;
    }

    logoutFromAutoLogin() {
        LocalStorageHandler.AUTO_LOGIN.remove();
        window.location.reload();
    }

    ngOnInit(): void {
        // Set the theme and scheme based on the configuration
        combineLatest([
            this._fuseConfigService.config$,
            this._fuseMediaWatcherService.onMediaQueryChange$(['(prefers-color-scheme: dark)', '(prefers-color-scheme: light)']),
        ]).pipe(
            takeUntil(this._unsubscribeAll),
            map(([config, mql]) => {
                const options = {
                    scheme: config.scheme,
                    theme: config.theme,
                };

                // If the scheme is set to 'auto'...
                if (config.scheme === 'auto') {
                    // Decide the scheme using the media query
                    options.scheme = mql.breakpoints['(prefers-color-scheme: dark)'] ? 'dark' : 'light';
                }

                return options;
            }),
        ).subscribe((options) => {
            // Store the options
            this.scheme = options.scheme;
            this.theme = options.theme;

            // Update the scheme and theme
            this._updateScheme();
            this._updateTheme();
        });

        // Subscribe to config changes
        this._fuseConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config: FuseConfig) => {
                // Store the config
                this.config = config;

                // Update the layout
                this._updateLayout();
            });

        // Subscribe to NavigationEnd event
        this._router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            takeUntil(this._unsubscribeAll),
        ).subscribe(() => {
            // Update the layout
            this._updateLayout();
        });

        // Set the app version
        this._renderer2.setAttribute(this._document.querySelector('[ng-version]'), 'fuse-version', FUSE_VERSION);

        // Set the OS name
        this._renderer2.addClass(this._document.body, this._fusePlatformService.osName);

        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        window.addEventListener(CommonEvents.PROFILE_DETAILS_UPDATED, (event: CustomEvent<CurrentUser>) => {
            this.setup(event.detail);
        });

        this.merchantUserService.getCurrentUser().pipe(takeUntil(this._unsubscribeAll)).subscribe(user => {
            this.filteredNavigation = this.authService.getPermittedNavigations();
            this.setup(user);
        });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    private setup(user: CurrentUser) {
        this.user = user;
        this.profilePic = ImageUtils.generateThumbnailPath(user.profilePic, true);
    }

    private _updateLayout(): void {
        // Get the current activated route
        let route = this._activatedRoute;
        while (route.firstChild) {
            route = route.firstChild;
        }

        // 1. Set the layout from the config
        this.layout = this.config.layout;

        // 2. Get the query parameter from the current route and
        // set the layout and save the layout to the config
        const layoutFromQueryParam = route.snapshot.queryParamMap.get('layout');
        if (layoutFromQueryParam) {
            this.layout = layoutFromQueryParam;
            if (this.config) {
                this.config.layout = layoutFromQueryParam;
            }
        }

        // 3. Iterate through the paths and change the layout as we find
        // a config for it.
        //
        // The reason we do this is that there might be empty grouping
        // paths or componentless routes along the path. Because of that,
        // we cannot just assume that the layout configuration will be
        // in the last path's config or in the first path's config.
        //
        // So, we get all the paths that matched starting from root all
        // the way to the current activated route, walk through them one
        // by one and change the layout as we find the layout config. This
        // way, layout configuration can live anywhere within the path and
        // we won't miss it.
        //
        // Also, this will allow overriding the layout in any time so we
        // can have different layouts for different routes.
        const paths = route.pathFromRoot;
        paths.forEach((path) => {
            // Check if there is a 'layout' data
            if (path.routeConfig && path.routeConfig.data && path.routeConfig.data.layout) {
                // Set the layout
                this.layout = path.routeConfig.data.layout;
            }
        });
    }

    private _updateScheme(): void {
        // Remove class names for all schemes
        this._document.body.classList.remove('light', 'dark');

        // Add class name for the currently selected scheme
        this._document.body.classList.add(this.scheme);
    }

    private _updateTheme(): void {
        // Find the class name for the previously selected theme and remove it
        this._document.body.classList.forEach((className: string) => {
            if (className.startsWith('theme-')) {
                this._document.body.classList.remove(className, className.split('-')[1]);
            }
        });

        // Add class name for the currently selected theme
        this._document.body.classList.add(this.theme);
    }
}
