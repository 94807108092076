import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
    selector: 'mdo-separator',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './separator.component.html',
    styles: []
})
export class SeparatorComponent {
    @Input()
    margin: number = 8;

    @Input()
    marginTop: number;

    @Input()
    marginBottom: number;
}
