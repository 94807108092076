import {Component, HostBinding} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
    selector: 'mdo-dialog-content',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './dialog-content.component.html',
    styles: []
})
export class DialogContentComponent {
    @HostBinding('class')
    public class = 'overflow-y-scroll mx-6';
}
