import {Injectable} from '@angular/core';
import {CurrentUser} from "@app/model/admin-user";
import {ActionType, ComponentType} from "@app/model/role";
import {NavigationItem} from "../../../@fuse/components/navigation";
import {navigation} from "@app/layout/navigation";
import {KeycloakService} from "keycloak-angular";
import {LocalStorageHandler} from "@app/local-storage/local-storage-handler";

@Injectable({providedIn: 'root'})
export class AuthService {

    constructor(private keycloakService: KeycloakService) {
    }

    getCurrentUser(): CurrentUser {
        return LocalStorageHandler.CURRENT_USER.get();
    }

    signOut(redirectUrl?: string) {
        sessionStorage.clear();
        LocalStorageHandler.CURRENT_USER.remove();
        this.keycloakService.logout(redirectUrl);
    }

    signIn(redirectUri: string) {
        this.keycloakService.login({
            redirectUri: redirectUri,
            locale: LocalStorageHandler.CURRENT_LANGUAGE.get()?.code
        });
    }

    public getPermittedNavigations(): NavigationItem[] {
        const navigationItems = this.findPermittedNavigations(navigation);
        const currentUser = this.getCurrentUser();
        if (!currentUser.enableMultiBranches) {
            const pos = navigationItems.findIndex(value => value.id == 'branches');
            if (pos > -1) {
                navigationItems.splice(pos, 1);
            }
        }

        return navigationItems;
    }

    public getInitialRoutingPath(): string {
        return this.findInitialRoutingPath(this.getPermittedNavigations());
    }

    public findInitialRoutingPath(navs: NavigationItem[]): string {
        let path = null;
        if (navs.length > 0) {
            if (navs[0].children?.length > 0) {
                path = navs[0].children[0].link;
            } else {
                path = navs[0].link;
            }
        }

        return path;
    }

    hasAnyPermission(components: ComponentType[]): boolean {
        const currentUser = this.getCurrentUser();
        if (currentUser == null || currentUser.grants == null || Object.keys(currentUser.grants).length == 0) {
            return false;
        }

        for (let component of components) {
            if (currentUser.grants[component]) {
                return true;
            }
        }

        return false;
    }

    hasAnyActionPermission(component: ComponentType, actions: ActionType[]): boolean {
        const currentUser = this.getCurrentUser();
        if (actions == null || actions.length == 0 || currentUser == null || currentUser.grants == null ||
            Object.keys(currentUser.grants).length == 0) {
            return false;
        }

        for (let action of actions) {
            if (currentUser.grants[component]?.includes(action)) {
                return true;
            }
        }

        return false;
    }

    hasExactPermission(component: ComponentType, action?: ActionType): boolean {
        const currentUser = this.getCurrentUser();
        if (currentUser == null || currentUser.grants == null || Object.keys(currentUser.grants).length == 0) {
            return false;
        }

        if (action) {
            return currentUser.grants[component]?.includes(action);
        }

        return currentUser.grants[component] != null;
    }

    private findPermittedNavigations(navigationItems: NavigationItem[]): NavigationItem[] {
        const navs: NavigationItem[] = [];
        for (let navigationItem of navigationItems) {
            if (navigationItem.components == null || navigationItem.components.length == 0 || this.hasAnyPermission(navigationItem.components)) {
                if (navigationItem.children != null && navigationItem.children.length > 0) {
                    navigationItem.children = this.findPermittedNavigations(navigationItem.children);
                }
                navs.push(navigationItem);
            }
        }

        return navs;
    }
}
