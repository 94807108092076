<!-- Button -->
<button *ngIf="enabled" [matMenuTriggerFor]="languages" mat-button>
    <span class="flex items-center mat-ripple">
        <span class="relative w-6 rounded-sm overflow-hidden">
            <img [alt]="'Flag image for ' + activeCurrency?.flag" [src]="imgUrl" class="w-full">
        </span>
        <span class="ml-3">{{activeCurrency?.code}}</span>
    </span>
</button>

<!-- Language menu -->
<mat-menu
    #languages="matMenu"
    [xPosition]="'before'">
    <ng-container *ngFor="let currency of availableCurrencies; trackBy: trackByFn">
        <button
                (click)="setActiveCurrency(currency)"
                mat-menu-item>
            <span class="flex items-center">
                <span class="relative w-6 rounded-sm overflow-hidden">
                    <img
                            [alt]="'Flag image for ' + currency.flag"
                            class="w-full"
                            src="https://raw.githubusercontent.com/lipis/flag-icons/main/flags/4x3/{{currency.flag}}.svg">
                </span>
                <span class="ml-3">{{currency.name}}</span>
            </span>
        </button>
    </ng-container>
</mat-menu>

