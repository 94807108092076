import {ErrorResponse, ResponseMetadata} from "@moodeon-commons/model/common";
import {FieldHeaderResponse, HeaderResponse, TableHeaderResponse} from "@app/model/header";

export class ApiResponse<T> {
    static SUCCESS_CODE = 1000;

    static BAD_REQUEST = 4000;
    static NOT_FOUND = 4004;
    static INVALID_DATA_TYPE = 4002;
    static DUPLICATED_ENTRY = 4009;

    static INVALID_DATA = 4100;

    static DATA_REMOVING_ERROR = 5300;
    static DATA_SAVING_ERROR = 5100;
    static DATA_RETRIEVING_ERROR = 5200;
    static DATA_MAPPING_ERROR = 5400;

    code: string;
    message: string;
    payload: T;
    errors: ErrorResponse;
    metadata: ResponseMetadata;
    tableHeaders: TableHeaderResponse[];
    fieldHeaders: FieldHeaderResponse[];
}
