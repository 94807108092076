import {MatDialog} from '@angular/material/dialog';
import {BaseModel} from "@moodeon-commons/model/base-model";
import {Injector} from "@angular/core";
import {BaseCrudService} from "@moodeon-commons/service/base-crud-service";
import {BaseListViewPage} from "@moodeon-commons/component/base-list-view-page";
import {BaseFilter} from "@moodeon-commons/model/common";
import {DialogData} from "@moodeon-commons/model/dialog-data";

export abstract class BaseListViewPageWithAddViewEditDialog<ListItem extends BaseModel, Req extends BaseModel = ListItem, Res extends BaseModel = Req, Filter extends BaseFilter = BaseFilter> extends BaseListViewPage<ListItem, Req, Res, Filter> {

    public dialog: MatDialog;

    protected constructor(_crudService: BaseCrudService<ListItem, Req, Res>, _injector: Injector) {
        super(_crudService, _injector);
        this.dialog = _injector.get(MatDialog);
    }

    protected setupDialogData(dialogData: DialogData): void {

    }

    protected showViewAddEditDialog(dialogData: DialogData): void {
        this.setupDialogData(dialogData);
        const dialogRef = this.dialog.open(this.getViewAddEditDialog(), {
            data: dialogData
        });

        dialogRef.afterClosed().subscribe(result => {
            this.onDialogClosed(result);
        });
    }

    protected onDialogClosed(result): void {
        if (result) {
            this.loadData();
        }
    }

    protected executeViewAction(data: any): void {
        this.showViewAddEditDialog({mode: 'VIEW', data: data, additionalData: {}});
    }

    protected executeEditAction(data: any): void {
        this.showViewAddEditDialog({mode: 'EDIT', data: data, additionalData: {}});
    }

    protected executeAddNewAction(): void {
        this.showViewAddEditDialog({mode: 'ADD', additionalData: {}});
    }

    protected abstract getViewAddEditDialog(): any;
}
