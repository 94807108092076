<fuse-alert
        (dismissedChanged)="onDismiss($event)"
        [appearance]="'fill'"
        [dismissible]="true"
        [type]="data.type">
    <span *ngIf="data.title" fuseAlertTitle>{{data.title}}</span>
    <mdo-icon *ngIf="data.icon" [iconLib]="data.iconLib" [icon]="data.icon" className="icon-size-5"
              fuseAlertIcon></mdo-icon>
    <span>{{data?.message}}</span>
</fuse-alert>
