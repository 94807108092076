import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslocoModule} from "@ngneat/transloco";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {LoadingBarComponent} from "@moodeon-commons/component/loading-bar/loading-bar.component";

@Component({
    selector: 'mdo-page-container',
    standalone: true,
    imports: [CommonModule, TranslocoModule, MatProgressBarModule, LoadingBarComponent],
    templateUrl: './page-container.component.html',
    styles: []
})
export class PageContainerComponent {
}
