import {BaseModel} from "@moodeon-commons/model/base-model";
import {BaseFilter} from "@moodeon-commons/model/common";

export class NotificationListItemResponse extends BaseModel {
    id: number;
    image: string;
    title: string;
    description: string;
    type: string;
    createdAt: Date;
    delivered: boolean;
    read: boolean;
    params: { [key: string]: any }
}

export class NotificationFilter extends BaseFilter {
    relatedUserId?: number;
    relatedBranchId?: number;
    delivered?: boolean;
    read?: boolean;
    status?: number;
    markAsDelivered?: boolean;
}
