import {Subject} from "rxjs";
import {Component, OnDestroy, OnInit} from "@angular/core";

@Component({
    template: ''
})
export abstract class BaseComponent implements OnDestroy, OnInit {
    protected _unsubscribeAll: Subject<any> = new Subject<any>();

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    ngOnInit(): void {
    }
}
