import {Language} from "@moodeon-commons/model/language";
import {LocalStorageHandler} from "@app/local-storage/local-storage-handler";

export class TranslationHandler {
    public static DEFAULT_LANGUAGE = 'EN';
    public static LANG_CODE: string;

    static getCurrentLanguage(): Language {
        return LocalStorageHandler.CURRENT_LANGUAGE.get();
    }

    static setCurrentLanguage(language: Language): void {
        LocalStorageHandler.CURRENT_LANGUAGE.set(language);
    }

    static getBrowserLanguage(): string {
        const browserLang = navigator.language != null ? navigator.language : TranslationHandler.DEFAULT_LANGUAGE;
        return (browserLang.split('-')[0]).toUpperCase();
    }

    static setAvailableLanguages(languages: Language[]) {
        LocalStorageHandler.LANGUAGES.set(languages);
    }

    static getAvailableLanguages(): Language[] {
        return LocalStorageHandler.LANGUAGES.get();
    }

    static getDefaultLangCode(): string {
        if (!TranslationHandler.LANG_CODE) {
            TranslationHandler.LANG_CODE = LocalStorageHandler.CURRENT_USER.get()?.defaultLanguage || TranslationHandler.DEFAULT_LANGUAGE;
        }
        return TranslationHandler.LANG_CODE;
    }

    static updateInMemoryDefaultLangCode(langCode: string) {
        TranslationHandler.LANG_CODE = langCode;
    }
}
