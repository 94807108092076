export const environment = {
    production: false,
    title: 'Dev Environment',
    apiBaseUrl: 'https://merchant-api.moodeon.fi',
    imageBaseUrl: 'https://merchant.moodeon.fi/',
    merchantUiBaseUrlTemplate: 'https://{slug}.moodeon.fi',
    keycloak: {
        authUrl: 'https://auth.moodeon.fi',
        clientId: 'merchant-ui',
        adminClientId: 'admin-ui',
        adminRealm: 'moodeon-admin'
    }
};
