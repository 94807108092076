<mdo-dialog-container *transloco="let t; read: 'order';" widthClasses="max-w-540 sm:min-w-80 md:min-w-140 lg:min-w-160">
    <mdo-dialog-header [title]="t('newOrder') + ' - #' + orderResponse.orderNo"></mdo-dialog-header>
    <mdo-dialog-content>
        <div class="flex flex-col gap-2.5 mt-4">
            <div class="flex">
                <span class="text-lg font-semibold text-gray-600 w-40">{{ t('type') }}</span>
                <span>{{ t('serviceType.' + orderResponse.type) }}</span>
            </div>

            <div *ngIf="orderResponse.type == 'DELIVERY'" class="flex">
                <span class="text-lg font-semibold text-gray-600 w-40">{{ t('deliveryAddress') }}</span>
                <span>{{ orderResponse.deliveryDetails?.address?.fullAddress }}</span>
            </div>

            <div class="flex">
                <span class="text-lg font-semibold text-gray-600 w-40">{{ t('customer') }}</span>
                <span>{{ orderResponse.customer?.firstName }} {{ orderResponse.customer?.lastName }}</span>
            </div>

            <div class="flex">
                <span class="text-lg font-semibold text-gray-600 w-40">{{ t('items') }}</span>
                <div>
                    <div *ngFor="let item of orderResponse.items">
                        <span>{{ item.quantity }}x</span>
                        <span class="ml-4">
                                <span>{{ item.itemName }}</span>
                                <span *ngIf="item.variantName"> - {{ item.variantName }}</span>
                            </span>
                        <span class="ml-4">{{ item.price | convertCurrency }}</span>
                    </div>
                </div>
            </div>

            <div class="flex">
                <span class="text-lg font-semibold text-gray-600 w-40">{{ t('total') }}</span>
                <span>{{ orderResponse.total | convertCurrency }}</span>
            </div>
        </div>
    </mdo-dialog-content>
    <mdo-dialog-footer (dismissButtonClicked)="rejectOrder()" (neutralButtonClicked)="dialogRef.close()"
                       (submitButtonCLicked)="approveOrder()"
                       [showNeutralButton]="true" [warnDismissButton]="true"
                       dismissButtonLabel="action.reject"
                       neutralButtonLabel="action.notNow" submitButtonLabel="action.approve"></mdo-dialog-footer>
</mdo-dialog-container>
