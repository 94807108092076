export const notificationMetadata: {[key: string]: NotificationMetadata} = {
    'NEW_ORDER': {
        urlTemplate: 'orders/{orderId}',
        paramKeys: ['orderId']
    },
    'NEW_INVOICE': {
        urlTemplate: 'billing/invoices/{invoiceId}',
        paramKeys: ['invoiceId']
    }
};

export interface NotificationMetadata {
    urlTemplate: string;
    paramKeys?: string[];
}
