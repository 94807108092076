import {AfterViewInit, Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BaseInputComponent} from "@moodeon-commons/component/input/base-input-component";
import {TranslocoModule} from "@ngneat/transloco";
import {ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {ThemePalette} from "@angular/material/core";
import {MatTooltipModule} from "@angular/material/tooltip";

@Component({
    selector: 'mdo-toggle-input',
    standalone: true,
    imports: [CommonModule, TranslocoModule, ReactiveFormsModule, MatInputModule, MatSlideToggleModule, MatTooltipModule],
    templateUrl: './toggle-input.component.html',
    styles: []
})
export class ToggleInputComponent extends BaseInputComponent implements AfterViewInit {
    @Input()
    color: ThemePalette = 'primary';

    @Input()
    trueValue: string;

    @Input()
    falseValue: string;

    @Input()
    titleDisplayMode: 'label' | 'tooltip' = 'label';

    clicked(event: MouseEvent) {
        console.log('ddd');
        this.formGroup.get(this.key).setValue(!this.formGroup.get(this.key).value);
    }

    onToggleChange(isChecked: boolean): void {
        if (this.trueValue) {
            this.formGroup.get(this.key)?.setValue(isChecked ? this.trueValue : this.falseValue);
        } else {
            this.formGroup.get(this.key)?.setValue(isChecked);
        }
    }
}
