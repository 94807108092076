import {BaseFilter} from "@moodeon-commons/model/common";
import {BaseModel} from "@moodeon-commons/model/base-model";
import {Lookup} from "@app/model/lookup";
import {DeliveryDetailsRequest, DeliveryDetailsResponse} from "@app/model/delivery-details";
import {PickUpDetailsRequest, PickUpDetailsResponse} from "@app/model/pick-up-details";
import {DineInDetailsRequest, DineInDetailsResponse} from "@app/model/dine-in-details";
import {OrderPaymentListItemResponse} from "@app/model/order-payment";
import {OrderEventListItemResponse} from "@app/model/order-event";
import {OrderItemListItemResponse, OrderItemRequest} from "@app/model/order-item";
import {CustomerListItemResponse, CustomerResponse} from "@app/model/customer";
import {ContactPhone} from "@app/model/merchant";
import {BranchListItemResponse} from "@app/model/branch";
import {CustomerSearchMode} from "@app/modules/order/add-order/add-order-shared-data";

export type ServiceType = 'DINE_IN' | 'PICK_UP' | 'DELIVERY';

export class OrderListItemResponse extends BaseModel {
    orderNo: string;
    type: string;
    typeCode: string;
    scheduled: boolean;
    scheduledDateTime: string;
    scheduledInSeconds: number;
    scheduledInTimeStr: string;
    late = false;
    remindInSeconds: number;
    alreadyReminded: boolean;
    branch: string;
    customer: string;
    paymentStatus: Lookup;
    amount: number;
    balanceAmount: number;
    status: Lookup;
    address: string;
    toBePreparedAt: string
    timeToPrepareInSeconds: number;
    timeToPrepareInMins: number;
}

export class OrderRequest extends BaseModel {
    draftedOrderId: number;
    type: ServiceType;
    branchId: number;
    scheduled: boolean;
    customer: OrderCustomerRequest;
    deliveryDetails: DeliveryDetailsRequest;
    pickUpDetails: PickUpDetailsRequest;
    dineInDetails: DineInDetailsRequest;
    items: OrderItemRequest[];
    paymentStatus: string;
    paymentReference: string;
    preferredPaymentMethod: string;
    paidAmount: number;
    notes: string;
    status: string;
    subTotal: number;
    serviceFee: number;
    tax: number;
    total: number;
}

export class OrderCustomerRequest extends BaseModel {
    customerId: number;
    contact: ContactPhone;
    contactDisplayable: string;
    email: string;
    firstName: string;
    lastName: string;
    searchMode: CustomerSearchMode;
}

export class OrderCustomer extends BaseModel {
    customer: CustomerListItemResponse | CustomerResponse;
    contact: ContactPhone;
    contactDisplayable: string;
    email: string;
    firstName: string;
    lastName: string;
}

export interface OrderReviewListItemResponse {
    rating: number;
    comment: string;
    createdAt: Date;
}


export interface OrderReviewResponse {
    rating: number;
    comment: string;
    createdAt: Date;
    orderNo: number;
    orderId: number;
    customer: CustomerListItemResponse;
}

export interface OrderReviewRequest {
    rating: number;
    comment: string;
}

export class OrderResponse extends BaseModel {
    orderNo: string;
    type: ServiceType;
    branch: BranchListItemResponse;
    customer: CustomerListItemResponse;
    deliveryDetails: DeliveryDetailsResponse;
    pickUpDetails: PickUpDetailsResponse;
    dineInDetails: DineInDetailsResponse;
    paymentStatus: Lookup;
    scheduled: boolean;
    preferredPaymentMethod: string;
    paidAmount: number;
    payments: OrderPaymentListItemResponse[];
    events: OrderEventListItemResponse[];
    items: OrderItemListItemResponse[];
    review: OrderReviewListItemResponse;
    finalActionPerformedAt: Date;
    cancelDenyReason: string;
    subTotal: number;
    serviceFee: number;
    tax: number;
    total: number;
    balanceAmount: number;
    notes: string;
    status: Lookup;
}

export class OrderFilter extends BaseFilter {
    branchId: number;
    customerId: number;
    status: string;
    type: ServiceType;
    paymentStatus: string;
}
