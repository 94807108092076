import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DefaultRouteHandlerComponent} from "@moodeon-commons/component/default-route-handler/default-route-handler.component";

@Component({
    selector: 'app-auto-login',
    standalone: true,
    imports: [CommonModule, DefaultRouteHandlerComponent],
    templateUrl: './auto-login.component.html',
    styles: []
})
export class AutoLoginComponent {

}
