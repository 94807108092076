import {NgFor, NgIf, NgTemplateOutlet, UpperCasePipe} from '@angular/common';
import {ChangeDetectorRef, Component, ViewEncapsulation} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {TranslocoModule} from '@ngneat/transloco';
import {takeUntil} from 'rxjs';
import {CommonEvents} from "@moodeon-commons/util/common-events";
import {BranchService} from "@app/service/branch.service";
import {ApiUtils} from "@moodeon-commons/util/api-utils";
import {MerchantUserService} from "@app/service/merchant-user.service";
import {CurrentUser} from "@app/model/admin-user";
import {BranchListItemResponse} from "@app/model/branch";
import {LocalStorageHandler} from "@app/local-storage/local-storage-handler";
import {BaseComponent} from "@moodeon-commons/component/base-component";

@Component({
    selector: 'branch-filter',
    templateUrl: './branch-filter.component.html',
    encapsulation: ViewEncapsulation.None,
    exportAs: 'branches',
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, NgTemplateOutlet, NgFor, UpperCasePipe, NgIf, TranslocoModule],
})
export class BranchFilterComponent extends BaseComponent {
    branches: BranchListItemResponse[];
    currentBranch: BranchListItemResponse;
    filterVisible = false;
    enabled = false;

    constructor(private branchService: BranchService, private cdr: ChangeDetectorRef, private merchantUserService: MerchantUserService) {
        super();
        window.addEventListener(CommonEvents.CURRENT_USER_LOADED, (event: CustomEvent<CurrentUser>) => {
            this.enabled = event.detail.enableMultiBranches;
            if (!this.filterVisible && (event.detail.branch == null || event.detail.branch.id == null || event.detail.branch.id == 0)) {
                LocalStorageHandler.CURRENT_BRANCH.remove();
            }
            this.initUi(event.detail);
        });
    }

    ngOnInit() {
        this.enabled = LocalStorageHandler.CURRENT_USER.get()?.enableMultiBranches;
        this.initUi(this.merchantUserService.getCurrentUserLocal());
    }

    initUi(currentUser: CurrentUser) {
        if (currentUser == null) {
            return;
        }

        if (currentUser.branch?.id > 0) {
            this.filterVisible = false;
            LocalStorageHandler.CURRENT_BRANCH.set(currentUser.branch);
            return;
        }

        this.filterVisible = true;
        this.currentBranch = LocalStorageHandler.CURRENT_BRANCH.get();

        this.branchService.getList({pageSize: ApiUtils.DEFAULT_LIST_PAGE_SIZE})
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(value => {
                this.branches = value.payload;

            });
    }

    setCurrentBranch(branch: BranchListItemResponse): void {
        LocalStorageHandler.CURRENT_BRANCH.set(branch);

        if (this.currentBranch?.id == branch?.id) {
            return;
        }

        this.currentBranch = branch;
        CommonEvents.dispatchEvent(CommonEvents.CURRENT_BRANCH_CHANGED, branch);
    }

    trackByFn(index: number, item: any): any {
        return item.id || index;
    }
}
