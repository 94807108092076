import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {DatePipe, NgClass, NgFor, NgIf, NgTemplateOutlet} from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import {MatButton, MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterLink} from '@angular/router';
import {interval, repeat, Subject, takeUntil} from 'rxjs';
import {NotificationService} from "@app/service/notification.service";
import {NotificationListItemResponse} from "@app/model/notification";
import {CommonEvents} from "@moodeon-commons/util/common-events";
import {Language} from "@moodeon-commons/model/language";
import {notificationMetadata} from "@app/layout/common/notifications/notification-metadata";
import {MatSnackBar} from "@angular/material/snack-bar";
import {NotificationAlertComponent} from "@moodeon-commons/component/notification-alert/notification-alert.component";
import {TranslocoModule} from "@ngneat/transloco";
import {PluralizePipe} from "@moodeon-commons/pipe/pluralize.pipe";
import {ResponseMetadata} from "@moodeon-commons/model/common";
import {OrderService} from "@app/service/order.service";
import {MatDialog} from "@angular/material/dialog";
import {NewOrderPopUpComponent} from "@app/layout/common/notifications/new-order-pop-up/new-order-pop-up.component";

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'notifications',
    standalone: true,
    imports: [MatButtonModule, NgIf, MatIconModule, MatTooltipModule, NgFor, NgClass, NgTemplateOutlet, RouterLink, DatePipe, TranslocoModule, PluralizePipe],
})
export class NotificationsComponent implements OnInit, OnDestroy {
    notifications: NotificationListItemResponse[] = [];
    newNotifications: NotificationListItemResponse[] = [];
    unreadCount: number = 0;
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private _unsubscribeListenNotifications: Subject<any> = new Subject<any>();
    alreadyListening = false;

    constructor(private _changeDetectorRef: ChangeDetectorRef, private notificationService: NotificationService,
                private orderService: OrderService, private dialog: MatDialog,
                private _overlay: Overlay, protected snackBar: MatSnackBar, private _viewContainerRef: ViewContainerRef) {
        window.addEventListener(CommonEvents.LANGUAGE_CHANGED, (event: CustomEvent<Language>) => {
            this.loadInitialNotifications();
        });

        window.addEventListener(CommonEvents.ALL_NOTIFICATIONS_CHANGED, (event: CustomEvent<any>) => {
            this.loadInitialNotifications();
        });
    }

    ngOnInit(): void {
        this.loadInitialNotifications();
    }

    loadInitialNotifications() {
        this.notificationService.getList({pageSize: 5})
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(response => {
                this.notifications = response.payload || [];
                this.calculateUnreadCount(response.metadata);
                this._changeDetectorRef.markForCheck();
                this.startListeningToNotifications();
            });
    }

    startListeningToNotifications() {
        if (this.alreadyListening) {
            return;
        }

        this.alreadyListening = true;
        this.notificationService.listen()
            .pipe(takeUntil(this._unsubscribeListenNotifications))
            .pipe(repeat({delay: () => interval(5000)}))
            .subscribe(response => {
                this.calculateUnreadCount(response.metadata);
                if (!response.payload || response.payload.length === 0) {
                    return;
                }

                this.newNotifications = response.payload;
                this.notifications.unshift(...response.payload)
                this._changeDetectorRef.markForCheck();

                response.payload?.forEach(notification => {
                    this.showNotification(notification);
                    CommonEvents.dispatchEvent(CommonEvents.NEW_NOTIFICATION, notification);
                });
            }, error => {
                this._unsubscribeListenNotifications.next(null);
                this._unsubscribeListenNotifications.complete();

                setTimeout(() => {
                    this._unsubscribeListenNotifications = new Subject<any>();
                    this.alreadyListening = false;
                    this.startListeningToNotifications();
                }, 5000);
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        this._unsubscribeListenNotifications.next(null);
        this._unsubscribeListenNotifications.complete();

        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    openPanel(): void {
        // Return if the notifications panel or its origin is not defined
        if (!this._notificationsPanel || !this._notificationsOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));
    }

    closePanel(): void {
        this._overlayRef.detach();
    }

    markAllAsRead(): void {
        const notificationId = this.notifications?.map(value => value.id)
            .reduce((previousValue, currentValue) => previousValue > currentValue ? previousValue : currentValue)
        this.notificationService.markAllAsReadUntil(notificationId).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(response => {
                CommonEvents.dispatchEvent(CommonEvents.NOTIFICATIONS_CHANGED, null);
                this.notifications.filter(value => value.id <= notificationId)
                    .forEach(notification => notification.read = true);
            });
    }

    toggleRead(notification: NotificationListItemResponse): void {
        const currentStatus = notification.read;

        this.markAsRead(notification, !currentStatus);
    }

    markAsRead(notification: NotificationListItemResponse, read: boolean) {
        notification.read = read;
        this.toggleUnreadCountBy(read ? -1 : 1);
        this._changeDetectorRef.markForCheck();
        this.notificationService.toggleReadStatus(notification.id, read).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(response => {
                CommonEvents.dispatchEvent(CommonEvents.NOTIFICATIONS_CHANGED, null);
            }, error => {
                notification.read = !read;
                this.toggleUnreadCountBy(read ? 1 : -1);
                this._changeDetectorRef.markForCheck();
            });
    }

    delete(notification: NotificationListItemResponse): void {
        this.closePanel();
        this.notificationService.delete(notification.id).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(response => {
                CommonEvents.dispatchEvent(CommonEvents.NOTIFICATIONS_CHANGED, null);
                this.loadInitialNotifications();
            });
    }

    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom',
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom',
                    },
                ]),
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    private calculateUnreadCount(metadata: ResponseMetadata): void {
        if (!metadata || metadata['UNREAD_COUNT'] == null) {
            return;
        }

        this.unreadCount = metadata['UNREAD_COUNT'];
    }

    private toggleUnreadCountBy(number: number): void {
        this.unreadCount = this.unreadCount + number;
    }

    hasLink(notification: NotificationListItemResponse): boolean {
        if (notification == null) {
            return false;
        }

        return notificationMetadata[notification.type]?.urlTemplate?.length > 0;
    }

    getRouterLink(notification: NotificationListItemResponse): string {
        if (notification == null) {
            return null;
        }

        const metadata = notificationMetadata[notification.type];

        if (!metadata?.urlTemplate || metadata?.urlTemplate === '') {
            return null;
        }

        if (!metadata.paramKeys || metadata.paramKeys.length == 0) {
            return metadata?.urlTemplate;
        }

        let finalUrl = metadata?.urlTemplate;
        for (let paramKey of metadata.paramKeys) {
            finalUrl = finalUrl.replace('{' + paramKey + '}', notification.params?.[paramKey]);
        }

        return finalUrl;
    }

    onClicked(notification: NotificationListItemResponse) {
        this.closePanel();
        if (!notification.read) {
            this.markAsRead(notification, true);
        }
    }

    public showNotification(notification: NotificationListItemResponse) {
        if (notification.type == 'NEW_ORDER') {
            this.showNewOrderModal(notification.params['orderId']);
        } else {
            this.snackBar.openFromComponent<NotificationAlertComponent>(NotificationAlertComponent, {
                duration: 3000,
                horizontalPosition: 'end',
                panelClass: 'notification-alert',
                data: notification
            });
        }
    }

    viewAllNotifications() {
        this.closePanel();
    }

    patchOrderStatus(orderId: number, status: string, metadata?: any) {
        this.orderService.patchOrderStatus(orderId, status, metadata)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(response => {

            });
    }

    private showNewOrderModal(orderId: number) {
        this.orderService.getById(orderId)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(response => {
                const dialogRef = this.dialog.open(NewOrderPopUpComponent, {
                    data: response.payload,
                    disableClose: true
                });

                dialogRef.afterClosed().subscribe(result => {
                    console.log('res', result);
                    if (!result) {
                        return;
                    }

                    this.patchOrderStatus(orderId, result.status, result.metadata);
                });
            });
    }
}
