import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BreadcrumbItem} from "@moodeon-commons/model/breadcrumb-item";
import {IconComponent} from "@moodeon-commons/component/icon/icon.component";
import {TranslocoModule} from "@ngneat/transloco";
import {RouterLink} from "@angular/router";

@Component({
    selector: 'mdo-breadcrumb',
    standalone: true,
    imports: [CommonModule, IconComponent, TranslocoModule, RouterLink],
    templateUrl: './breadcrumb.component.html',
    styles: []
})
export class BreadcrumbComponent {

    @Input()
    breadcrumbItems: BreadcrumbItem[];
}
