import {BooleanInput} from '@angular/cdk/coercion';
import {NgClass, NgIf} from '@angular/common';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {Router, RouterLink} from '@angular/router';
import {Subject, takeUntil} from 'rxjs';
import {KeycloakService} from "keycloak-angular";
import {AuthService} from "@app/core/auth/auth.service";
import {MerchantUserService} from "@app/service/merchant-user.service";
import {CurrentUser} from "@app/model/admin-user";
import {ImageUtils} from "@moodeon-commons/util/image-utils";
import {TranslocoModule} from "@ngneat/transloco";
import {ComponentType} from "@app/model/role";
import {CommonEvents} from "@moodeon-commons/util/common-events";

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user',
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, NgIf, MatIconModule, NgClass, MatDividerModule, TranslocoModule, RouterLink],
})
export class UserComponent implements OnInit, OnDestroy {
    @Input() showAvatar: boolean = true;
    user: CurrentUser = new CurrentUser();
    userPlaceholder = '../../../assets/images/placeholders/user_placeholder.png';
    profilePic = this.userPlaceholder;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private authService: AuthService,
        private _changeDetectorRef: ChangeDetectorRef,
        protected _router: Router,
        private merchantUserService: MerchantUserService) {
    }

    ngOnInit(): void {
        window.addEventListener(CommonEvents.PROFILE_DETAILS_UPDATED, (event: CustomEvent<CurrentUser>) => {
            this.setup(event.detail);
        });

        this.merchantUserService.getCurrentUser().pipe(takeUntil(this._unsubscribeAll)).subscribe(user => {
            this.setup(user);
        });
    }

    signOut(): void {
        this.authService.signOut(window.location.origin);
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    hasPermission(component: ComponentType) {
        return this.authService.hasAnyPermission([component]);
    }

    private setup(user: CurrentUser) {
        this.user = user;
        this.profilePic = ImageUtils.generateThumbnailPath(user.profilePic, true);
        this._changeDetectorRef.detectChanges();
    }
}
