/* eslint-disable */
import {NavigationItem} from '@fuse/components/navigation';

export const navigation: NavigationItem[] = [
    {
        id: 'dashboard',
        type: 'basic',
        icon: 'heroicons_outline:cursor-arrow-rays',
        link: '/dashboard',
        components: []
    },
    {
        id: 'orders',
        type: 'collapsable',
        icon: 'heroicons_outline:clipboard-document-list',
        link: '/orders',
        components: ['ORDER'],
        children: [
            {
                id: 'ordersList',
                type: 'basic',
                link: '/orders',
                components: ['ORDER']
            }
        ]
    },
    {
        id: 'tableBooking',
        type: 'collapsable',
        icon: 'heroicons_outline:squares-2x2',
        link: '/table-booking',
        components: ['TABLE_BOOKING', 'ROOM', 'ROOM_AVAILABILITY', 'TABLE'],
        children: [
            {
                id: 'tableBookings',
                type: 'basic',
                link: '/table-booking/all',
                components: ['TABLE_BOOKING']
            },
            {
                id: 'rooms',
                type: 'basic',
                link: '/table-booking/rooms',
                components: ['ROOM']
            },
            {
                id: 'roomAvailability',
                type: 'basic',
                link: '/table-booking/room-availability',
                components: ['ROOM_AVAILABILITY']
            },
            {
                id: 'table',
                type: 'basic',
                link: '/table-booking/tables',
                components: ['TABLE']
            }
        ]
    },
    {
        id: 'branches',
        type: 'basic',
        icon: 'heroicons_outline:building-storefront',
        components: ['BRANCH'],
        link: '/branches'
    },
    {
        id: 'billing',
        type: 'collapsable',
        icon: 'heroicons_outline:receipt-percent',
        link: '/billing',
        components: ['MERCHANT_INVOICE', 'PAYMENT', 'INVOICE_SETTINGS'],
        children: [
            {
                id: 'invoices',
                type: 'basic',
                link: '/billing/invoices',
                components: ['MERCHANT_INVOICE']
            },
            {
                id: 'payments',
                type: 'basic',
                link: '/billing/payments',
                components: ['PAYMENT']
            }
        ]
    },
    {
        id: 'attributes',
        type: 'collapsable',
        icon: 'heroicons_outline:document-text',
        link: '/attributes',
        components: ['INGREDIENT', 'ALLERGEN', 'COOKING_REFERENCE', 'DELIVERY_OPTION', 'VARIANT'],
        children: [
            {
                id: 'cuisine',
                type: 'basic',
                link: '/attributes/cuisine',
                components: ['CUISINE']
            },
            {
                id: 'featured',
                type: 'basic',
                link: '/attributes/featured',
                components: ['FEATURED']
            },
            {
                id: 'tags',
                type: 'basic',
                link: '/attributes/tags',
                components: ['TAG']
            },
            {
                id: 'ingredients',
                type: 'basic',
                link: '/attributes/ingredients',
                components: ['INGREDIENT']
            },
            {
                id: 'allergens',
                type: 'basic',
                link: '/attributes/allergens',
                components: ['ALLERGEN']
            },
            {
                id: 'cookingReferences',
                type: 'basic',
                link: '/attributes/cooking-references',
                components: ['COOKING_REFERENCE']
            },
            {
                id: 'deliveryOptions',
                type: 'basic',
                link: '/attributes/delivery-options',
                components: ['DELIVERY_OPTION']
            },
            {
                id: 'variants',
                type: 'basic',
                link: '/attributes/variants',
                components: ['VARIANT']
            }
        ]
    },
    {
        id: 'products',
        type: 'collapsable',
        icon: 'heroicons_outline:cake',
        link: '/products',
        components: ['ITEM_CATEGORY', 'ADD_ON_CATEGORY', 'ADD_ON_ITEM', 'ITEM'],
        children: [
            {
                id: 'categories',
                type: 'basic',
                link: '/products/categories',
                components: ['ITEM_CATEGORY']
            },
            {
                id: 'addOnCategories',
                type: 'basic',
                link: '/products/add-on-categories',
                components: ['ADD_ON_CATEGORY']
            },
            {
                id: 'addOnItems',
                type: 'basic',
                link: '/products/add-on-items',
                components: ['ADD_ON_ITEM']
            },
            {
                id: 'items',
                type: 'basic',
                link: '/products/items',
                components: ['ITEM']
            }
        ]
    },
    {
        id: 'inventory',
        type: 'collapsable',
        icon: 'heroicons_outline:arrows-right-left',
        link: '/inventory',
        components: ['SUPPLIER'],
        children: [
            {
                id: 'suppliers',
                type: 'basic',
                link: '/inventory/suppliers',
                components: ['SUPPLIER']
            }
        ]
    },
    {
        id: 'userManagement',
        type: 'collapsable',
        icon: 'heroicons_outline:user-group',
        link: '/users',
        components: ['MERCHANT_USER', 'MERCHANT_ROLE'],
        children: [
            {
                id: 'usersList',
                type: 'basic',
                exactMatch: true,
                link: '/users',
                components: ['MERCHANT_USER']
            },
            {
                id: 'rolesList',
                type: 'basic',
                link: '/users/roles',
                components: ['MERCHANT_ROLE']
            }
        ]
    },
    {
        id: 'customers',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/customers',
        components: ['CUSTOMER']
    },
    {
        id: 'merchantSettings',
        type: 'collapsable',
        icon: 'heroicons_outline:cog-8-tooth',
        link: '/settings',
        components: ['MERCHANT_CONFIG', 'CUSTOMER_CONFIG', 'ORDER_SETTINGS', 'HEADER'],
        children: [
            {
                id: 'merchant',
                type: 'basic',
                link: '/settings/merchant',
                components: ['MERCHANT_CONFIG']
            },
            {
                id: 'customerApp',
                type: 'basic',
                link: '/settings/customer-app',
                components: ['CUSTOMER_CONFIG']
            },
            {
                id: 'orderSettings',
                type: 'basic',
                link: '/settings/order',
                components: ['ORDER_SETTINGS']
            },
            {
                id: 'headers',
                type: 'basic',
                link: '/settings/headers',
                components: ['HEADER']
            }
        ]
    }
];
