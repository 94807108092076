import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {FormControl, FormGroup, UntypedFormBuilder} from '@angular/forms';
import {Subject, takeUntil} from "rxjs";
import {Language} from "@moodeon-commons/model/language";

@Component({
    template: ''
})
export abstract class BaseInputComponent implements AfterViewInit, OnDestroy {

    @Input()
    title;

    @Input()
    enableValueChangeListener: boolean;

    @Input()
    label;

    @Input()
    value;

    @Input()
    minValue;

    @Input()
    maxValue;

    @Input()
    minLength: number;

    @Input()
    maxLength: number;

    @Input()
    initialValue: any;

    @Input()
    translationScope: string;

    @Input()
    readOnly = false;

    @Input()
    showLabel = true;

    @Input()
    key: string;

    @Input()
    formGroup: FormGroup;

    @Input()
    customValidationMsgs: { [key: string]: string };

    @Input()
    classes = '';

    @Input()
    hint: string;

    @Input()
    hintLabel: string;

    @Input()
    acceptedChars: string[];

    @Input()
    forbiddenChars: string[];

    @Input()
    language: Language;

    @Input()
    placeholder: string;

    @Input()
    tooltipText: string;

    @Input()
    tooltipPosition: 'above' | 'after' | 'before' | 'left' | 'below' | 'right' = 'above';

    @Input()
    inlineMode = false;

    @Output()
    valueChange: EventEmitter<any> = new EventEmitter<any>();

    protected _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(private _formBuilder: UntypedFormBuilder, protected cdr?: ChangeDetectorRef) {
    }

    get translationLabel(): string {
        return this.label || this.key;
    }

    ngAfterViewInit(): void {
        if (this.key == null) {
            this.key = 'tempInput';
        }

        if (this.key.includes('.')) {
            this.key = this.key.replace('.', '_');
        }

        if (this.formGroup == null) {
            this.formGroup = this._formBuilder.group({});
        }

        if (!this.formGroup.get(this.key)) {
            this.formGroup.addControl(this.key, new FormControl(this.initialValue))
        }

        if (this.enableValueChangeListener) {
            this.setupValueChangeListener();
        }

        if (this.cdr != null) {
            this.cdr.detectChanges();
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    focus() {
    }

    protected setupValueChangeListener() {
        this.formGroup.get(this.key).valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(value => {
                this.valueChange.emit(value);
            });
    }
}
