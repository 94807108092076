import {Injectable} from '@angular/core';
import {BaseCrudService} from "@moodeon-commons/service/base-crud-service";
import {HttpClient} from "@angular/common/http";
import {NotificationListItemResponse} from "@app/model/notification";
import {Observable, tap} from "rxjs";
import {ApiResponse} from "@moodeon-commons/model/api-response";

@Injectable({
    providedIn: 'root'
})
export class NotificationService extends BaseCrudService<NotificationListItemResponse, any, any> {

    constructor(private _httpClient: HttpClient) {
        super(_httpClient);
    }

    getContextPath(): string {
        return "notifications";
    }

    listen(): Observable<ApiResponse<NotificationListItemResponse[]>> {
        return this._httpClient.get<ApiResponse<NotificationListItemResponse[]>>(this.concatUrl(this.baseUrl, this.getContextPath(), 'listen'))
            .pipe(tap((response) => {
                this._pagination.next(response.metadata);
                this._items.next(response.payload);
            }));
    }

    markAllAsReadUntil(notificationId: number): Observable<ApiResponse<any>> {
        return this._httpClient.patch<ApiResponse<any>>(this.concatUrl(this.baseUrl, this.getContextPath(), notificationId, 'mark-all-as-read'), null);
    }

    toggleReadStatus(notificationId: number, read: boolean): Observable<ApiResponse<any>> {
        return this._httpClient.patch<ApiResponse<any>>(this.concatUrl(this.baseUrl, this.getContextPath(), notificationId, 'toggle-read', read), null);
    }
}
