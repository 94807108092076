import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree
} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthService} from '@app/core/auth/auth.service';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';
import {ComponentType} from "@app/model/role";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(private readonly _keycloak: KeycloakService, private _router: Router,
                private authService: AuthService) {
        super(_router, _keycloak);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._check(redirectUrl);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        return super.canActivate(route, state);
    }


    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        return this._check('/');
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.authenticated) {
            await this._keycloak.login({
                redirectUri: window.location.origin + state.url
            });
        }

        const components: ComponentType[] = route.data.components;
        if (components != null && components.length > 0) {
            const hasAnyPermission = this.authService.hasAnyPermission(components);

            if (!hasAnyPermission) {
                const eligibleNavigations = this.authService.getPermittedNavigations();
                const index = eligibleNavigations.findIndex(value => value.link.startsWith(state.url));
                let path = null;
                if (index > -1) {
                    const navigationItem = eligibleNavigations[index];
                    if (navigationItem.children?.length > 0) {
                        path = navigationItem.children[0].link;
                    }
                }

                if (path == null) {
                    path = this.authService.findInitialRoutingPath(eligibleNavigations);
                }

                await this._router.navigate([path]);
            }
            return hasAnyPermission;
        }

        return this.authenticated;
    }

    private _check(redirectURL: string): Observable<boolean> {
        if (this.authenticated) {
            return of(true);
        }

        this._keycloak.login({redirectUri: redirectURL});

        return of(false);
    }
}
