import {OnDestroy, Pipe, PipeTransform} from "@angular/core";
import {CurrencyPipe} from "@angular/common";
import {CurrencyService} from "@app/service/currency.service";
import {Subject} from "rxjs";
import {CurrencyHandler} from "@moodeon-commons/handler/currency-handler";

@Pipe({
    name: 'convertCurrency',
    pure: false,
    standalone: true
})
export class CurrencyConverterPipe implements PipeTransform, OnDestroy {
    protected _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(private currencyPipe: CurrencyPipe, currencyService: CurrencyService) {
    }

    transform(value: number, currentCurrency?: string, display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean, digitsInfo?: string, locale?: string): string | null {
        currentCurrency = CurrencyHandler.getCurrentCurrency()?.code;
        return this.currencyPipe.transform(value, currentCurrency, display, digitsInfo, locale);
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
