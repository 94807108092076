<ng-container *ngIf="enabled">
    <ng-container *transloco="let t; read: 'branchFilter'">
        <button *ngIf="filterVisible" [matMenuTriggerFor]="branchMenu" mat-button>
    <span class="flex flex-col items-center mat-ripple">
        <span class="text-primary text-xs font-semibold">{{t('currentBranch')}}</span>
        <span class="mt-1 self-start">{{currentBranch?.name || t('none')}}</span>
    </span>
        </button>

        <mat-menu #branchMenu="matMenu" [xPosition]="'before'">
            <button (click)="setCurrentBranch(null)" mat-menu-item>
            <span class="flex items-center">
                <span class="ml-3">{{t('none')}}</span>
            </span>
            </button>
            <ng-container *ngFor="let branch of branches; trackBy: trackByFn">
                <button (click)="setCurrentBranch(branch)" mat-menu-item>
            <span class="flex items-center">
                <span class="ml-3">{{branch.name}}</span>
            </span>
                </button>
            </ng-container>
        </mat-menu>
    </ng-container>
</ng-container>
