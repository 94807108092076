<div class="justify-end border-t flex-1 pb-2 px-8 inline" mat-dialog-actions>
    <ng-content></ng-content>
    <mdo-separator margin="0"></mdo-separator>
    <div class="flex justify-between w-full px-6 pt-6">
        <mdo-button (buttonClick)="neutralButtonClicked.emit($event)" *ngIf="showNeutralButton && !readOnly"
                    type="stroked">{{ neutralButtonLabel | transloco }}
        </mdo-button>
        <div class="flex items-center justify-end w-full">
            <mdo-button (buttonClick)="dismissButtonClicked.emit($event)"
                        *ngIf="showDismissButton && !warnDismissButton && !readOnly"
                        type="stroked">{{ dismissButtonLabel | transloco }}
            </mdo-button>
            <mdo-button (buttonClick)="dismissButtonClicked.emit($event)"
                        *ngIf="showDismissButton && warnDismissButton && !readOnly"
                        color="warn" type="flat">{{ dismissButtonLabel | transloco }}
            </mdo-button>
            <mdo-button (buttonClick)="submitButtonCLicked.emit($event)" *ngIf="showSubmitButton && !readOnly"
                        [className]="'ml-4'" color="primary"
                        type="flat">{{ submitButtonLabel | transloco }}
            </mdo-button>
            <mdo-button (buttonClick)="dismissButtonClicked.emit($event)" *ngIf="readOnly" [className]="'ml-4'"
                        color="primary"
                        type="flat">{{ 'action.ok' | transloco }}
            </mdo-button>
        </div>
    </div>
</div>
