import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoadingBarComponent} from "@moodeon-commons/component/loading-bar/loading-bar.component";
import {FuseLoadingBarComponent} from "../../../../@fuse/components/loading-bar";

@Component({
    selector: 'mdo-dialog-header',
    standalone: true,
    imports: [CommonModule, LoadingBarComponent, FuseLoadingBarComponent],
    templateUrl: './dialog-header.component.html',
    styles: []
})
export class DialogHeaderComponent {
    @Input()
    title: string;
}
