import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, tap} from 'rxjs';
import {ObjectUtils} from "@moodeon-commons/util/object-utils";
import {DeleteResponse} from "@moodeon-commons/model/delete-response";
import {BaseFilter, ResponseMetadata} from "@moodeon-commons/model/common";
import {ApiResponse} from "@moodeon-commons/model/api-response";
import {environment} from "../../environments/environment";

export abstract class BaseCrudService<ListItem, Req = ListItem, Res = Req> {
    baseUrl: string = environment.apiBaseUrl;
    protected _items: BehaviorSubject<ListItem[] | null> = new BehaviorSubject(null);
    protected _pagination: BehaviorSubject<ResponseMetadata | null> = new BehaviorSubject(null);

    protected constructor(private http: HttpClient) {
    }

    get items$(): Observable<ListItem[]> {
        return this._items.asObservable();
    }

    get pagination$(): Observable<ResponseMetadata> {
        return this._pagination.asObservable();
    }

    getList(filter?: BaseFilter | any): Observable<ApiResponse<ListItem[]>> {
        const filterJson = ObjectUtils.cloneAndRemoveNulls(filter);
        return this.http.get<ApiResponse<ListItem[]>>(this.baseUrl + '/' + this.getContextPath(), {params: {...filterJson}})
            .pipe(tap((response) => {
                this._pagination.next(response.metadata);
                this._items.next(response.payload);
            }));
    }

    getById(id: number, withHeaders?: boolean): Observable<ApiResponse<Res>> {
        return this.http.get<ApiResponse<Res>>(this.concatUrl(this.baseUrl, this.getContextPath(), id), withHeaders ? {params: {withHeaders}} : undefined);
    }

    create(requestBody: Req | FormData): Observable<ApiResponse<Res>> {
        return this.http.post<ApiResponse<Res>>(this.baseUrl + '/' + this.getContextPath(), requestBody);
    }

    update(id: number, requestBody: Req | FormData): Observable<ApiResponse<Res>> {
        return this.http.put<ApiResponse<Res>>(this.baseUrl + '/' + this.getContextPath() + '/' + id, requestBody);
    }

    delete(id: number): Observable<ApiResponse<DeleteResponse>> {
        return this.http.delete<ApiResponse<DeleteResponse>>(this.baseUrl + '/' + this.getContextPath() + '/' + id);
    }

    concatUrl(...paths: any[]): string {
        return paths.join('/');
    }

    abstract getContextPath(): string;
}
