export class TokenProps {
    public static token: string;
    public static tenant: string;

    public static getTenant(): string {
        if (TokenProps.tenant && TokenProps.tenant.length > 0) {
            return TokenProps.tenant;
        }

        let url = window.location.href;
        TokenProps.tenant = url.split(".")[0].replace('https://', '').replace('http://', '');
        return TokenProps.tenant;
    }
}
