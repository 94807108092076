import {Injectable} from '@angular/core';
import {BaseCrudService} from "@moodeon-commons/service/base-crud-service";
import {OrderListItemResponse, OrderRequest, OrderResponse, OrderReviewRequest} from "@app/model/order";
import {HttpClient} from "@angular/common/http";
import {BaseFilter} from "@moodeon-commons/model/common";
import {Observable, tap} from "rxjs";
import {ApiResponse} from "@moodeon-commons/model/api-response";
import {ObjectUtils} from "@moodeon-commons/util/object-utils";

@Injectable({
    providedIn: 'root'
})
export class OrderService extends BaseCrudService<OrderListItemResponse, OrderRequest, OrderResponse> {

    constructor(private _httpClient: HttpClient) {
        super(_httpClient);
    }

    getContextPath(): string {
        return "orders";
    }

    patchOrderStatus(id: number, status: string, metadata: any, filter?: BaseFilter | any): Observable<ApiResponse<OrderListItemResponse[]>> {
        const filterJson = ObjectUtils.cloneAndRemoveNulls(filter);
        return this._httpClient.patch<ApiResponse<OrderListItemResponse[]>>(this.concatUrl(this.baseUrl, this.getContextPath(), id, status), metadata, {params: {...filterJson}})
            .pipe(tap((response) => {
                this._pagination.next(response.metadata);
                this._items.next(response.payload);
            }));
    }

    printKitchenOrder(id: number, confirmIfNot?: boolean): Observable<Blob> {
        return this._httpClient.post(this.concatUrl(this.baseUrl, this.getContextPath(), id, 'print', 'kitchen-order'), null, {
            params: {confirmIfNot},
            responseType: 'blob'
        });
    }

    patchOrderStatusAndReturnResponse(id: number, status: string, metadata: any): Observable<ApiResponse<OrderResponse>> {
        return this._httpClient.patch<ApiResponse<OrderResponse>>(this.concatUrl(this.baseUrl, this.getContextPath(), id, status, 'details'), metadata);
    }

    initOrder(orderRequest: any): Observable<ApiResponse<OrderRequest>> {
        return this._httpClient.post<ApiResponse<OrderRequest>>(this.concatUrl(this.baseUrl, this.getContextPath(), 'draft/init'), orderRequest);
    }

    updateDraftedOrder(draftedOrderId: number, orderRequest: any): Observable<ApiResponse<OrderRequest>> {
        return this._httpClient.put<ApiResponse<OrderRequest>>(this.concatUrl(this.baseUrl, this.getContextPath(), 'draft', draftedOrderId), orderRequest);
    }

    checkoutAndConfirm(draftedOrderId: number, orderRequest: OrderRequest): Observable<ApiResponse<OrderResponse>> {
        return this._httpClient.put<ApiResponse<OrderResponse>>(this.concatUrl(this.baseUrl, this.getContextPath(), 'draft/checkout-and-confirm', draftedOrderId), orderRequest);
    }

    getDraftedOrderById(draftedOrderId: number): Observable<ApiResponse<OrderRequest>> {
        return this._httpClient.get<ApiResponse<OrderRequest>>(this.concatUrl(this.baseUrl, this.getContextPath(), 'draft', draftedOrderId));
    }

    rateOrder(orderId: number, reviewRequest: OrderReviewRequest): Observable<ApiResponse<OrderResponse>> {
        return this._httpClient.post<ApiResponse<OrderResponse>>(this.concatUrl(this.baseUrl, this.getContextPath(), orderId, 'rate'), reviewRequest);
    }
}
