import {Route} from '@angular/router';
import {AuthGuard} from '@app/core/auth/auth.guard';
import {DefaultRouteHandlerComponent} from "@moodeon-commons/component/default-route-handler/default-route-handler.component";
import {AutoLoginComponent} from "@moodeon-commons/component/auto-login/auto-login.component";
import {DashboardComponent} from "@app/modules/dashboard/dashboard.component";
import {
    ViewAllNotificationsComponent
} from "@app/layout/common/notifications/view-all-notifications/view-all-notifications.component";

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    {
        path: '',
        pathMatch : 'full',
        component: DefaultRouteHandlerComponent
    },
    {
        path: 'auto-login/:token',
        component: AutoLoginComponent
    },
    {
        path: 'dashboard',
        loadChildren: () => import('app/modules/dashboard/dashboard.routes')
    },
    {
        path: 'notifications',
        component: ViewAllNotificationsComponent
    },
    {
        path: 'orders',
        canActivate: [AuthGuard],
        data: {components:['ORDER']},
        loadChildren: () => import('app/modules/order/order.routes')
    },
    {
        path: 'table-booking',
        canActivate: [AuthGuard],
        data: {components:['TABLE_BOOKING', 'ROOM', 'ROOM_AVAILABILITY', 'TABLE']},
        loadChildren: () => import('app/modules/table-booking/table-booking.routes')
    },
    {
        path: 'branches',
        canActivate: [AuthGuard],
        data: {components:['BRANCH']},
        loadChildren: () => import('@app/modules/branch/branch.routes')
    },
    {
        path: 'billing',
        canActivate: [AuthGuard],
        data: {components:['MERCHANT_INVOICE', 'PAYMENT', 'INVOICE_SETTINGS']},
        loadChildren: () => import('app/modules/invoice/invoice.routes')
    },
    {
        path: 'attributes',
        canActivate: [AuthGuard],
        data: {components: ['INGREDIENT', 'ALLERGEN', 'COOKING_REFERENCE', 'DELIVERY_OPTION', 'VARIANT']},
        loadChildren: () => import('app/modules/attribute/attribute.routes')
    },
    {
        path: 'products',
        canActivate: [AuthGuard],
        data: {components: ['ITEM_CATEGORY', 'ADD_ON_CATEGORY', 'ADD_ON_ITEM', 'ITEM']},
        loadChildren: () => import('app/modules/product/product.routes')
    },
    {
        path: 'inventory',
        canActivate: [AuthGuard],
        data: {components:['SUPPLIER']},
        loadChildren: () => import('app/modules/inventory/inventory.routes')
    },
    {
        path: 'users',
        canActivate: [AuthGuard],
        data: {components:['MERCHANT_USER', 'MERCHANT_ROLE']},
        loadChildren: () => import('app/modules/user/user.routes')
    },
    {
        path: 'customers',
        canActivate: [AuthGuard],
        data: {components:['CUSTOMER']},
        loadChildren: () => import('app/modules/customer/customer.routes')
    },
    {
        path: 'profile',
        canActivate: [AuthGuard],
        data: {components:['PROFILE', 'PASSWORD']},
        loadChildren: () => import('app/modules/profile/profile.routes')
    },
    {
        path: 'settings',
        canActivate: [AuthGuard],
        data: {components: ['MERCHANT_CONFIG', 'CUSTOMER_CONFIG', 'ORDER_SETTINGS', 'HEADER']},
        loadChildren: () => import('app/modules/settings/settings.routes')
    },
    {
        path: '**',
        redirectTo: ''
    }
];
