import {Injectable} from '@angular/core';
import {BaseCrudService} from "@moodeon-commons/service/base-crud-service";
import {
    BranchAvailability,
    BranchAvailabilityDay,
    BranchListItemResponse,
    BranchResponse,
    PatchBranchDataRequest
} from "@app/model/branch";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ApiResponse} from "@moodeon-commons/model/api-response";
import {AddressResponse} from "@app/model/address";

@Injectable({
    providedIn: 'root'
})
export class BranchService extends BaseCrudService<BranchListItemResponse, BranchResponse, BranchResponse> {

    constructor(private _httpClient: HttpClient) {
        super(_httpClient);
    }

    getContextPath(): string {
        return "branches";
    }

    getBranchAvailability(branchId: number): Observable<ApiResponse<BranchAvailability>> {
        return this._httpClient.get<ApiResponse<BranchAvailability>>(this.concatUrl(this.baseUrl, this.getContextPath(), branchId, 'availability'));
    }

    updateBranchAvailability(branchId: number, availabilityReq: BranchAvailabilityDay[]): Observable<ApiResponse<BranchAvailability>> {
        return this._httpClient.put<ApiResponse<BranchAvailability>>(this.concatUrl(this.baseUrl, this.getContextPath(), branchId, 'availability'), availabilityReq);
    }

    getBranchAddress(branchId: number): Observable<ApiResponse<AddressResponse>> {
        return this._httpClient.get<ApiResponse<AddressResponse>>(this.concatUrl(this.baseUrl, this.getContextPath(), branchId, 'address'));
    }

    updateBranchAddress(branchId: number, addressRequest: any): Observable<ApiResponse<AddressResponse>> {
        return this._httpClient.put<ApiResponse<AddressResponse>>(this.concatUrl(this.baseUrl, this.getContextPath(), branchId, 'address'), addressRequest);
    }

    patchBranchData(branchId: number, request: PatchBranchDataRequest): Observable<ApiResponse<any>> {
        return this._httpClient.patch<ApiResponse<any>>(this.concatUrl(this.baseUrl, this.getContextPath(), branchId), request);
    }
}
