import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ControlValueAccessor} from "@angular/forms";
import {ThemePalette} from "@angular/material/core";
import {IconLib} from "@moodeon-commons/model/icon-lib.ts";
import {MatIconModule} from "@angular/material/icon";

@Component({
    selector: 'mdo-icon',
    standalone: true,
    imports: [CommonModule, MatIconModule],
    templateUrl: './icon.component.html',
    styles: []
})
export class IconComponent implements ControlValueAccessor, OnInit {
    @Input()
    iconLib: IconLib = 'mat';

    @Input()
    icon: string;

    @Input()
    className = 'icon-size-5';

    @Input()
    fillColor: string;

    @Input()
    themeColor: ThemePalette;

    constructor() {
    }

    writeValue(obj: any): void {

    }

    registerOnChange(fn: any): void {

    }

    registerOnTouched(fn: any): void {

    }

    setDisabledState?(isDisabled: boolean): void {

    }

    ngOnInit(): void {
    }
}
