import {environment} from "../../environments/environment";
import {FormBuilder} from "@angular/forms";

export class ImageUtils {
    static IMG_PLACEHOLDER_PATH = 'assets/images/placeholders/image_placeholder.jpg';
    static IMG_THUMB_PLACEHOLDER_PATH = 'assets/images/placeholders/image_placeholder_thumb.jpg';

    static USER_IMG_PLACEHOLDER_PATH = 'assets/images/placeholders/user_placeholder.png';
    static USER_IMG_THUMB_PLACEHOLDER_PATH = 'assets/images/placeholders/user_placeholder_thumb.png';

    static generateImagePath(imageName: string, userImage = false): string {
        if (imageName == null) {
            return null;
        }

        return environment.imageBaseUrl + imageName;
    }

    static generateThumbnailPath(imageName: string, userImage = false): string {
        if (imageName == null) {
            return null;
        }

        const strings = imageName.split('.');
        strings[strings.length - 2] = strings[strings.length - 2] + '_thumb';
        return ImageUtils.generateImagePath(strings.join('.'), userImage);
    }

    static createImageFormGroup(formBuilder: FormBuilder, value: any, isThumbnail = false, isUserImage = false) {
        return formBuilder.group({
            path: [ImageUtils.generateImagePath(value, isUserImage)],
            file: [],
            isUserImage: isUserImage,
            removed: false
        });
    }
}
