import {Component, OnDestroy} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Router} from "@angular/router";
import {AuthService} from "@app/core/auth/auth.service";
import {MerchantUserService} from "@app/service/merchant-user.service";
import {Subject, takeUntil} from "rxjs";

@Component({
    selector: 'mdo-default-route-handler',
    standalone: true,
    imports: [CommonModule],
    template: '',
    styles: []
})
export class DefaultRouteHandlerComponent implements OnDestroy {
    protected _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(private authService: AuthService, private router: Router, merchantUserService: MerchantUserService) {
        merchantUserService.getCurrentUser(false, true).pipe(takeUntil(this._unsubscribeAll)).subscribe(currentUser => this.initRoute());
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    private initRoute() {
        this.router.navigate([this.authService.getInitialRoutingPath()]);
    }
}
