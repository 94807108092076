import {IconLib} from "@moodeon-commons/model/icon-lib.ts";
import {ActionType} from "@app/model/role";

export class MenuItem {
    action: string;
    label?: string;
    icon?: string;
    iconLib?: IconLib;
    iconClass?: string;
    bgClass?: string;
    visibilityFn?: any;
    showLabel?: boolean;
    labelPosition?: 'start' | 'end';
    showInColumn?: boolean = true;
    grantAction?: ActionType;
    subOptionsTranslationsScope?: string;
    subOptions?: MenuItem[];

    public static delete(): MenuItem {
        return {
            action: 'delete', label: 'delete', icon: 'trash-2', iconLib: 'feather',
            bgClass: 'bg-warn-100', iconClass: 'text-warn', showInColumn: true, grantAction: 'DELETE'
        };
    }

    public static view(): MenuItem {
        return {action: 'view', label: 'view', icon: 'eye', iconLib: 'feather', showInColumn: true, grantAction: 'VIEW_DETAIL'};
    }

    public static edit(): MenuItem {
        return {action: 'edit', label: 'edit', icon: 'edit', iconLib: 'feather', showInColumn: true, grantAction: 'UPDATE'};
    }

    public static print(): MenuItem {
        return {action: 'print', label: 'print', icon: 'printer', iconLib: 'feather', grantAction: 'PRINT'};
    }

    public static cancel(): MenuItem {
        return {action: 'cancel', label: 'cancel', icon: 'close', iconLib: 'mat_outline'};
    }
}
