import {Injectable} from '@angular/core';
import {BaseCrudService} from "@moodeon-commons/service/base-crud-service";
import {CurrentUser, MerchantUserListItemResponse, MerchantUserResponse} from "@app/model/admin-user";
import {HttpClient} from "@angular/common/http";
import {Observable, of, tap} from "rxjs";
import {ApiResponse} from "@moodeon-commons/model/api-response";
import {TranslationHandler} from "@moodeon-commons/handler/translation-handler";
import {CommonEvents} from "@moodeon-commons/util/common-events";
import {LocalStorageHandler} from "@app/local-storage/local-storage-handler";

@Injectable({
    providedIn: 'root'
})
export class MerchantUserService extends BaseCrudService<MerchantUserListItemResponse, MerchantUserResponse, MerchantUserResponse> {

    constructor(private _httpClient: HttpClient) {
        super(_httpClient);
    }

    getContextPath(): string {
        return "merchant-users";
    }

    autoLogin(tenant: string, token: string) {
        return this._httpClient.post<ApiResponse<MerchantUserResponse>>(this.baseUrl + '/' + this.getContextPath() + '/auto-login', null,
            {headers: {'x-tenant': tenant, 'Authorization': 'Bearer ' + token}});
    }

    getCurrentUserLocal(): CurrentUser {
        return LocalStorageHandler.CURRENT_USER.get();
    }

    getCurrentUser(force?: boolean, withGrants = false): Observable<CurrentUser> {
        let currentUser = this.getCurrentUserLocal();
        let needToLoadGrants = currentUser == null || currentUser.grants == null;

        if (currentUser && !needToLoadGrants && !force) {
            TranslationHandler.updateInMemoryDefaultLangCode(currentUser.defaultLanguage);
            return of(currentUser);
        }

        return this._httpClient.get<CurrentUser>(this.baseUrl + '/' + this.getContextPath() + '/current', {params: {withGrants: withGrants || needToLoadGrants}})
            .pipe(tap((currentUser) => {
                if (!withGrants) {
                    const currentUserLocal = LocalStorageHandler.CURRENT_USER.get();
                    if (currentUserLocal != null) {
                        currentUser.grants = currentUserLocal.grants;
                    }
                }

                TranslationHandler.updateInMemoryDefaultLangCode(currentUser.defaultLanguage);
                LocalStorageHandler.CURRENT_USER.set(currentUser);
                CommonEvents.dispatchEvent(CommonEvents.CURRENT_USER_LOADED, currentUser);
            }));
    }

    getProfile(): Observable<ApiResponse<MerchantUserResponse>> {
        return this._httpClient.get<ApiResponse<MerchantUserResponse>>(this.baseUrl + '/' + this.getContextPath() + '/profile');
    }

    updateProfile(requestBody: FormData): Observable<ApiResponse<MerchantUserResponse>> {
        return this._httpClient.put<ApiResponse<MerchantUserResponse>>(this.baseUrl + '/' + this.getContextPath() + '/profile', requestBody);
    }

    changePassword(requestBody: any): Observable<ApiResponse<any>> {
        return this._httpClient.post<ApiResponse<any>>(this.baseUrl + '/' + this.getContextPath() + '/change-password', requestBody);
    }
}
