import {Component, Inject} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {OrderResponse} from "@app/model/order";
import {DialogContainerComponent} from "@moodeon-commons/component/dialog/dialog-container/dialog-container.component";
import {DialogContentComponent} from "@moodeon-commons/component/dialog/dialog-content/dialog-content.component";
import {DialogFooterComponent} from "@moodeon-commons/component/dialog/dialog-footer/dialog-footer.component";
import {DialogHeaderComponent} from "@moodeon-commons/component/dialog/dialog-header/dialog-header.component";
import {FormsModule} from "@angular/forms";
import {LocalePanelComponent} from "@moodeon-commons/component/translatable/locale-panel/locale-panel.component";
import {SelectInputComponent} from "@moodeon-commons/component/input/select-input/select-input.component";
import {TextInputComponent} from "@moodeon-commons/component/input/text-input/text-input.component";
import {
    TranslationContainerComponent
} from "@moodeon-commons/component/translatable/translation-container/translation-container.component";
import {CurrencyConverterPipe} from "@moodeon-commons/pipe/currency-converter.pipe";
import {TranslocoModule} from "@ngneat/transloco";

@Component({
    selector: 'app-new-order-pop-up',
    standalone: true,
    imports: [CommonModule, DialogContainerComponent, DialogContentComponent, DialogFooterComponent, DialogHeaderComponent,
        FormsModule, LocalePanelComponent, SelectInputComponent, TextInputComponent, TranslationContainerComponent, CurrencyConverterPipe, TranslocoModule],
    providers: [CurrencyConverterPipe, CurrencyPipe],
    templateUrl: './new-order-pop-up.component.html',
    styles: []
})
export class NewOrderPopUpComponent {
    constructor(public dialogRef: MatDialogRef<NewOrderPopUpComponent>,
                @Inject(MAT_DIALOG_DATA) public orderResponse: OrderResponse) {
    }

    approveOrder() {
        this.dialogRef.close({status: 'CONFIRMED', metadata: null});
    }

    rejectOrder() {
        this.dialogRef.close({status: 'DENIED', metadata: null});
    }
}
